/* contact.css */

.container_con {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0rem;
  border: 2px solid #ddd;
  border-radius: 15px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#id_for_img {
  min-height: 400px; /* Set a minimum height for the image container */
  background-image: url('./15.svg'); /* Path to your image */
  background-size: cover;
  background-position: center;
}

#right_col {
  background-color: #fff;
  min-height: 400px; /* Set a minimum height for the right column */
}

@media (max-width: 768px) {
  .container_con {
    flex-direction: column;
  }

  .address-email-phone {
    flex-direction: column;
  }

  .items,
  form {
    width: 100%;
  }

  #id_for_img,
  #right_col {
    min-height: 300px; /* Adjust the minimum height for smaller screens */
  }
}

.left {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.left img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
}

.right {
  border-radius: 15px;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
}

.address-email-phone {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.address-box,
.email-phone-box {
  flex: 1;
  border: 3px solid #004aad;
  border-radius: 8px;
  padding: 20px;
}

.email-phone-box > div {
  margin-bottom: 20px;
}

h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #004aad;
}

form {
  margin-top: 20px;
}

button {
  background-color: #004aad;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #003788;
}

#c-social {
  margin-top: 20px;
}

#c-social a i {
  font-size: 1.7rem;
  margin-right: 10px;
  color: #004aad;
}

.contacts h3 {
  font-size: 2.5rem;
  margin-top: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #004aad;
}

#con1 {
  color: #004aad;
}

.contacts textarea,
.contacts input {
  width: 100%;
  padding: 15px;
  margin-bottom: 5px;
  outline: none;
  border: 1px solid grey;
  background-color: #fff;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 15px;
}

#countryCode {
  height: 55px;
  margin-right: 4px;
  border-color: #ddd; /* Changed from black to match the container border */
}

#country {
  width: 100%;
  height: 55px;
  border-color: #ddd; /* Changed from black to match the container border */
}

.right p,
h4 {
  color: #004aad;
}

.flexSB {
  display: flex;
  justify-content: space-between;
}

.popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #004aad;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup p {
  margin-bottom: 10px;
}

.popup button {
  background-color: #004aad;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup button:hover {
  background-color: #003788;
}
