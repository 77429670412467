.blog_container {
  margin-bottom: 70px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
}

.blog_item {
  width: 100%;
  max-width: 600px;
  margin: 40px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog_item .blog_img img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.blog_item .blog_text {
  padding: 40px 30px;
}

.blog_item h1 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin: 20px 0;
  transition: 0.5s;
  text-align: left;
}

.blog_item h1:hover {
  color: #027dff;
  cursor: pointer;
}

.blog_item span {
  text-transform: uppercase;
  font-weight: 500;
  color: #027dff;
}

.blog_item span label {
  margin-left: 10px;
  color: rgb(0, 0, 0);
  font-size: 14px;
}

.blog_item span:nth-last-child(1) label {
  color: #027dff;
}

.blog_item p {
  color: rgb(0, 0, 0);
  text-align: justify;
}

.blog_button_container {
  text-align: center;
  margin-top: auto;
  padding: 20px 0;
}

.blog_button_container button {
  background-color: #027dff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.blog_button_container button:hover {
  background-color: #025bbf;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .blog_item {
    margin: 20px;
  }
  
  .blog_item .blog_text {
    padding: 30px 20px;
  }
}

@media (max-width: 768px) {
  .blog_item {
    margin: 20px 10px;
  }

  .blog_item .blog_text {
    padding: 20px 10px;
  }

  .blog_item h1 {
    font-size: 20px;
  }

  .blog_item p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .blog_item {
    margin: 10px 5px;
  }

  .blog_item .blog_text {
    padding: 15px 10px;
  }

  .blog_item h1 {
    font-size: 18px;
  }

  .blog_item p {
    font-size: 12px;
  }
}
