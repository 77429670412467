.H_t-testimonial-section {
  text-align: center;
}

.H_t-h1 {
  font-size: 40px;
  margin-bottom: 10px;
  margin-top: 40px;
}

.H_t-h2 {
  font-size: 30px;
  margin-bottom: 5px;
}

.H_t-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 650px;
}

.H_t-slide .H_t-caption {
  border: 2px solid #004AAD;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  height: 600px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s inherit;
  margin-bottom: 30px;
}


.H_t-slide:hover .H_t-caption {
  transform: scale(1.005);
}

.H_t-slide .H_t-caption h3 {
  font-size: 28px;
  margin-bottom: 10px;
  margin-top: 5px;
  color: #004AAD;
}

.H_t-slide .H_t-caption{
  font-size: 22px;
  margin-bottom: 10px;
  color: #004AAD;
}

.H_t-icon {
  color: #004AAD;
  border-radius: 50%;
  height: 150px;
  width: 150px;
}

