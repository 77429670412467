
.Learn_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  justify-content: space-around;
  /* background-image: url("./7.jpg"); */
  background-size: cover;
  background-position: center;
  padding: 20px;
}
.Consult_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  justify-content: space-around;
  /* background-image: url("./1.jpg"); */
  background-size: cover;
  background-position: center;
  padding: 20px; /* Adjust as needed */
}
.head_service {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 40px;
  color: #004AAD;
}
#servicecard{
  max-height: 600px;
}
.service-title {
  color: #004AAD;
  font-size: 24px;
  margin-bottom: 10px;
}
#con_title{
  font-size: 3rem;
}
#learn_title{
  font-size: 3rem;
}
.s_items {
  padding: 20px;
  border: 1px solid #004AAD;
  border-radius: 8px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

h3 {
  text-align: center;
  margin-bottom: 30px;
}

h4,
p {
  color: #004AAD;
  margin-bottom: 10px;
}

.outline-btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #004AAD;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: auto;
}

.outline-btn:hover {
  background-color: #fcfcfc;
  color: #004AAD;
}

.Ser_description ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

.Ser_description li {
  color: #004AAD;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.Ser_description .bullet-icon {
  margin-right: 8px;
  color: #004AAD;
}
/* Media Queries */

/* Small Devices (Tablets) */
@media (min-width: 768px) {
  .Learn_container, .Consult_container {
    margin-left: 30px; /* Adjust margin for tablets */
    margin-right: 30px; /* Adjust margin for tablets */
  }

  .service-title {
    font-size: 28px;
  }


}

/* Medium Devices (Desktops) */
@media (min-width: 992px) {
  .Learn_container, .Consult_container {
    
    margin-left: 40px; /* Adjust margin for desktops */
    margin-right: 40px; /* Adjust margin for desktops */
  }

  .service-title {
    font-size: 32px;
  }


}

/* Large Devices (Large Desktops) */
@media (min-width: 1200px) {
  .Learn_container, .Consult_container {
    margin-left: 50px; /* Adjust margin for large desktops */
    margin-right: 50px; /* Adjust margin for large desktops */
  }

  .service-title {
    font-size: 36px;
  }


}