
#image_col {
  margin-top: 70px;
  border-radius: 2rem;
  background-image: url("./bgg.png");
  background-size: cover; /* Ensure the background image covers the entire div */
  background-position: right; /* Align the background image to the right */
  background-repeat: no-repeat;
  height: 75vh;
  width: 50%;
  /* background-attachment: fixed; */
}
@media (max-width: 760px) {
  #image_col {
    width: 90%;
    margin-left: 1.5rem;
    background-position: center; /* Align the background image to the right */


  }
}


#main_page{
  background-color: #ffffff;
}

#hero_heading h1 {
  color: #004aad;
  margin-top: 70px;
  text-align: left;
  margin-left: 40px;
  font-size: 41px;
}

#hero_heading h2 {
  color: #004aad;
  text-align: left;
  margin-left: 40px;
  font-size: 24px;
  margin-top: 20px;
}

.hero-text p {
  margin-bottom: 30px; /* Adjusted margin for better spacing */
  text-align: justify;
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 15px;
  font-size: 16px;
  padding: 10px;
}

.hero_button {
  /* margin-top: 12px;  */
  cursor: pointer;
  margin-left: 50px;
  height: 50px;
  /* margin-bottom: auto; */
}

.get-started-btn,
.view-course-btn {
  background-color: #004aad;
  color: #ffffff;
  border: 2px solid #004aad;
  transition: background-color 0.3s, color 0.3s;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  text-align: left;
  margin-right: 50px;
}

.get-started-btn:hover,
.view-course-btn:hover {
  background-color: #ffffff;
  color: #004aad;
}

/* Media Queries for Maximum Responsiveness */

/* Small Devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  #hero_heading h1 {
    font-size: 32px; /* Adjust font size for smaller screens */
    margin-left: 20px; /* Adjust margin for smaller screens */
  }

  #hero_heading h2 {
    font-size: 20px; /* Adjust font size for smaller screens */
    margin-left: 20px; /* Adjust margin for smaller screens */
    margin-top: 10px; /* Adjust margin for smaller screens */
  }

  .hero-text p {
    margin-left: 20px; /* Adjust margin for smaller screens */
    margin-right: 10px; /* Adjust margin for smaller screens */
  }

  
}

/* Medium Devices (tablets, 768px and up) */
@media (min-width: 768px) {
  #hero_heading h1 {
    font-size: 36px; /* Adjust font size for medium screens */
    margin-left: 40px; /* Adjust margin for medium screens */
  }

  #hero_heading h2 {
    font-size: 22px; /* Adjust font size for medium screens */
    margin-left: 40px; /* Adjust margin for medium screens */
    margin-top: 20px; /* Adjust margin for medium screens */
  }

  .hero-text p {
    margin-left: 40px; /* Adjust margin for medium screens */
    margin-right: 20px; /* Adjust margin for medium screens */
  }

  
}

/* Large Devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #hero_heading h1 {
    font-size: 41px; /* Reset font size for large screens */
    margin-left: 40px; /* Reset margin for large screens */
  }

  #hero_heading h2 {
    font-size: 24px; /* Reset font size for large screens */
    margin-left: 40px; /* Reset margin for large screens */
    margin-top: 20px; /* Reset margin for large screens */
  }

  .hero-text p {
    margin-left: 40px; /* Reset margin for large screens */
    margin-right: 15px; /* Reset margin for large screens */
  }

  
}