/* General Styles */
.head {
  padding: 20px 0;
}

.head_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo img {
  height: 170px;
  width: auto;
}

.social_icons {
  display: flex;
  gap: 1rem;
}

.s_media_icon {
  color: #004aad;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.s_media_icon:hover {
  color: #0a0a0a;
  transform: scale(1.1);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .head_container {
    flex-direction: column;
  }

  .social_icons {
    margin-top: 10px;
  }

  .logo img {
    height: 100px;
  }

  .s_media_icon {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .head_container {
    padding: 0 10px;
  }

  .logo img {
    height: 100px;
  }

  .s_media_icon {
    font-size: 1.2rem;
  }
}

/*--------header------------*/
#navbg {
  background-color: rgb(217, 230, 246);
  z-index: 1;
}

.navbar-brand img {
  margin-top: -0.3rem;
  margin-left: 2rem; /* Adjust margin for logo */
  width: auto; /* Ensure logo size adjusts appropriately */
  height: 50px; /* Set a fixed height for the logo */
}

.navbar-toggler {
  margin-right: 1rem; /* Adjust margin for toggler */
}

.navbar-nav .nav-link {
  margin-left: 24px;
  font-size: 16px; /* Default font size */
}

.start {
  background-color: #004aad;
  padding: 10px 20px; /* Adjust padding for start button */
  margin-left: auto;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
  margin-right: 60px;
}

.Gc_button {
  color: #fff;
  font-size: 18px;
}

.toggle {
  display: none;
}

/* Media Queries for Maximum Responsiveness */
@media (min-width: 576px) {
  .head .icon {
    margin-left: 5px; /* Adjust margin for social icons */
  }

  .navbar-toggler {
    margin-right: 0.5rem; /* Adjust margin for toggler */
  }
}

@media (min-width: 768px) {
  .head .icon {
    margin-left: 10px; /* Adjust margin for social icons */
    font-size: 28px; /* Increase font size for social icons */
  }
}

@media (min-width: 992px) {
  .start {
    clip-path: none;
    padding: 10px 15px; /* Adjust padding for start button */
    margin-right: 30px; /* Adjust margin for start button */
  }

  .navbar-nav .nav-link {
    font-size: 18px; /* Increase font size for navigation links */
  }
}

@media (min-width: 1200px) {
  .start {
    margin-right: 60px; /* Reset margin for start button */
  }
}

/* Dropdown Menu Styling */
.dropdown-menu {
  background-color: #fff;
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.dropdown-item {
  color: #333;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}