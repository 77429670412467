@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}

body {
  font-family: "Roboto", sans-serif;
  /* background-color: #b0b0ce; */
  
  background: linear-gradient(90deg, rgba(185,215,255,1) 0%, rgba(193,255,252,1) 51%, rgba(158,200,255,1) 100%);

}

.event-carousel {
  max-width: 60%; 
}

.gallery-carousel {
  max-width: 70%; 
  max-height: 50%;
}

.testimonial-carousel {
  max-width: 50%; 
}

/*------------global-----------*/
/* .container {
  max-width: 85%;
  margin: auto;
}

.flexSB {
  display: flex;
  justify-content: space-between;
}

.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgb(255, 255, 255);
  color: #004AAD;
  transition: 0.5s;
}

.icon:hover {
  cursor: pointer;
  background-color: #004AAD;
  color: #fff;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

.row {
  width: 50%;
}

button {
  padding: 17px 30px;
  background-color: #fff;
  color: #004AAD;
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}

button i {
  margin-left: 20px;
}

button:hover {
  box-shadow: none;
}

.primary-btn {
  background-color: #004AAD;
  color: #fff;
}

/*------------global-----------*/
#heading {
  text-align: center;
  padding: 40px 0;
}

#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #004AAD;
  text-transform: uppercase;
}

#heading h1 {
  font-size: 45px;
  margin: 20px 0;
  text-transform: capitalize;
}

p {
  line-height: 30px;
  font-size: 18px;
}

.back {
  background-image: url("");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 30%;
  padding-right: 50px;
  color: #fff;
  text-align: center;
}

.back h1 {
  font-size: 100px;
  font-weight: 400;
}

.back h2 {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}

.margin {
  margin-top: 40.3%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.flex {
  display: flex;
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.outline-btn {
  margin: 0;
  box-shadow: none;
  border: 2px solid #070707;
  width: 100%;
  transition: 0.5s;
}

.outline-btn:hover {
  /* background-color: #600fca; */
  color: #fff;
}

.grid3 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}

/* .padding {
  padding: 80px 0;
} */

@media screen and (max-width: 992px) {
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .grid,
  .grid2,
  .grid3 {
    grid-template-columns: repeat(2, 1fr);
  }

  .back {
    background-position: center;
    background-attachment: scroll;
    padding-top: 50%;
    height: 80vh;
  }

  .event-carousel,
  .gallery-carousel,
  .testimonial-carousel {
    max-width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .container {
    max-width: 95%;
  }
}
