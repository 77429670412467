/* Existing styles */
.testimonial-section {
  margin-top: 20px;
  padding: 20px;
}

.testimonial_h1 {
  font-size: 32px;
  color: #004AAD;
  margin-top: 20px;
  text-align: center; /* Center align the heading */
}

.T-carousel-container {
  max-width: 100%;
  margin: 0 auto;
}

.T-carousel-item-padding-40-px {
  padding: 40px;
}

.T-slide {
  position: relative;
}

.T-slide img {
  width: 100%;
  height: auto; /* Adjusted to maintain aspect ratio */
  max-height: 750px; /* Max height for larger screens */
  object-fit: contain; /* Ensure the entire image is displayed */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.T-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  text-align: center;
}

/* Button Styles */
.primary {
  background-color: #004AAD;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.primary:hover {
  background-color: #003b8b;
}

.mx-5 {
  margin-left: 20px;
  margin-right: 20px;
}

.my-5 {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .testimonial_h1 {
    font-size: 28px;
  }

  .T-slide img {
    max-height: 600px; /* Adjusted max height */
  }

  .primary {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 992px) {
  .testimonial_h1 {
    font-size: 24px;
  }

  .T-slide img {
    max-height: 500px; /* Adjusted max height */
  }

  .primary {
    font-size: 12px;
    padding: 6px 12px;
  }

  .mx-5 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .my-5 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .testimonial_h1 {
    font-size: 20px;
  }

  .T-slide img {
    max-height: 400px; /* Adjusted max height */
  }

  .primary {
    font-size: 10px;
    padding: 5px 10px;
  }

  .mx-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .my-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (max-width: 576px) {
  .testimonial_h1 {
    font-size: 18px;
  }

  .T-slide img {
    max-height: 300px; /* Adjusted max height */
  }

  .primary {
    font-size: 8px;
    padding: 4px 8px;
  }
}
