@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}

body {
  font-family: "Roboto", sans-serif;
  /* background-color: #b0b0ce; */
  
  background: linear-gradient(90deg, rgba(185,215,255,1) 0%, rgba(193,255,252,1) 51%, rgba(158,200,255,1) 100%);

}

.event-carousel {
  max-width: 60%; 
}

.gallery-carousel {
  max-width: 70%; 
  max-height: 50%;
}

.testimonial-carousel {
  max-width: 50%; 
}

/*------------global-----------*/
/* .container {
  max-width: 85%;
  margin: auto;
}

.flexSB {
  display: flex;
  justify-content: space-between;
}

.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgb(255, 255, 255);
  color: #004AAD;
  transition: 0.5s;
}

.icon:hover {
  cursor: pointer;
  background-color: #004AAD;
  color: #fff;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

.row {
  width: 50%;
}

button {
  padding: 17px 30px;
  background-color: #fff;
  color: #004AAD;
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}

button i {
  margin-left: 20px;
}

button:hover {
  box-shadow: none;
}

.primary-btn {
  background-color: #004AAD;
  color: #fff;
}

/*------------global-----------*/
#heading {
  text-align: center;
  padding: 40px 0;
}

#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #004AAD;
  text-transform: uppercase;
}

#heading h1 {
  font-size: 45px;
  margin: 20px 0;
  text-transform: capitalize;
}

p {
  line-height: 30px;
  font-size: 18px;
}

.back {
  background-image: url("");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 30%;
  padding-right: 50px;
  color: #fff;
  text-align: center;
}

.back h1 {
  font-size: 100px;
  font-weight: 400;
}

.back h2 {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}

.margin {
  margin-top: 40.3%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.flex {
  display: flex;
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.outline-btn {
  margin: 0;
  box-shadow: none;
  border: 2px solid #070707;
  width: 100%;
  transition: 0.5s;
}

.outline-btn:hover {
  /* background-color: #600fca; */
  color: #fff;
}

.grid3 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}

/* .padding {
  padding: 80px 0;
} */

@media screen and (max-width: 992px) {
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .grid,
  .grid2,
  .grid3 {
    grid-template-columns: repeat(2, 1fr);
  }

  .back {
    background-position: center;
    background-attachment: scroll;
    padding-top: 50%;
    height: 80vh;
  }

  .event-carousel,
  .gallery-carousel,
  .testimonial-carousel {
    max-width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .container {
    max-width: 95%;
  }
}

/* General Styles */
.head {
  padding: 20px 0;
}

.head_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo img {
  height: 170px;
  width: auto;
}

.social_icons {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.s_media_icon {
  color: #004aad;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.s_media_icon:hover {
  color: #0a0a0a;
  transform: scale(1.1);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .head_container {
    flex-direction: column;
  }

  .social_icons {
    margin-top: 10px;
  }

  .logo img {
    height: 100px;
  }

  .s_media_icon {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .head_container {
    padding: 0 10px;
  }

  .logo img {
    height: 100px;
  }

  .s_media_icon {
    font-size: 1.2rem;
  }
}

/*--------header------------*/
#navbg {
  background-color: rgb(217, 230, 246);
  z-index: 1;
}

.navbar-brand img {
  margin-top: -0.3rem;
  margin-left: 2rem; /* Adjust margin for logo */
  width: auto; /* Ensure logo size adjusts appropriately */
  height: 50px; /* Set a fixed height for the logo */
}

.navbar-toggler {
  margin-right: 1rem; /* Adjust margin for toggler */
}

.navbar-nav .nav-link {
  margin-left: 24px;
  font-size: 16px; /* Default font size */
}

.start {
  background-color: #004aad;
  padding: 10px 20px; /* Adjust padding for start button */
  margin-left: auto;
  -webkit-clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
          clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
  margin-right: 60px;
}

.Gc_button {
  color: #fff;
  font-size: 18px;
}

.toggle {
  display: none;
}

/* Media Queries for Maximum Responsiveness */
@media (min-width: 576px) {
  .head .icon {
    margin-left: 5px; /* Adjust margin for social icons */
  }

  .navbar-toggler {
    margin-right: 0.5rem; /* Adjust margin for toggler */
  }
}

@media (min-width: 768px) {
  .head .icon {
    margin-left: 10px; /* Adjust margin for social icons */
    font-size: 28px; /* Increase font size for social icons */
  }
}

@media (min-width: 992px) {
  .start {
    -webkit-clip-path: none;
            clip-path: none;
    padding: 10px 15px; /* Adjust padding for start button */
    margin-right: 30px; /* Adjust margin for start button */
  }

  .navbar-nav .nav-link {
    font-size: 18px; /* Increase font size for navigation links */
  }
}

@media (min-width: 1200px) {
  .start {
    margin-right: 60px; /* Reset margin for start button */
  }
}

/* Dropdown Menu Styling */
.dropdown-menu {
  background-color: #fff;
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.dropdown-item {
  color: #333;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}
#img_id {
  color: #004AAD;
}

.about-container {
  padding: 20px; /* Add padding for spacing */
}

h1 {
  margin-bottom: 15px; /* Adjust the margin at the bottom of the h1 element */
}

.about-overview-box,
.specialties-box {
  border: 2px solid #004AAD; /* Add a border */
  padding: 25px; /* Add padding inside the box */
  margin-top: 20px; /* Adjust margin at the top for spacing */
  margin-bottom: 20px; /* Add margin at the bottom for spacing */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

.about-overview-box p {
  color: #0c0c0c;
  text-align: justify;
}

.specialties-box h2 {
  font-size: 1.5rem; /* Adjust the font size of the heading */
  margin-bottom: 20px; /* Add margin at the bottom of the heading */
  color: #0c0c0c;
}

.specialties-cards {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px; /* Add some space between cards */
}

.specialty-card {
  background-color: #fff;
  border: 1px solid #dbdbe0;
  border-radius: 8px;
  padding: 20px; /* Increase padding for better spacing */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(15% - 20px);
  margin: 10px; /* Add margin for spacing */
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.specialty-card:hover {
  background-color: #004AAD;
  color: white;
}

.about-card {
  margin-top: 10px; /* Add margin at the top of the AboutCard */
}

.aboutHome {
  height: auto;
}

.aboutHome #heading {
  text-align: left;
}

.aboutHome .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aboutHome .right {
  padding: 75px 65px;
}

.aboutHome .item_flexSBs {
  margin-top: 10px;
}

.aboutHome .item_flexSB {
  background-color: #fff;
  margin-top: 0px;
  transition: 0.5s;
}

.aboutHome .img {
  width: 200px;
}

.About_img {
  font-size: 62px;
  color: #004AAD;
}

.About_img:hover {
  color: #fff;
  cursor: pointer;
}

.aboutHome img {
  width: 70px;
  height: 70px;
}

.aboutHome .item_flexSB h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.aboutHome .item_flexSB p {
  color: #999999;
  font-size: 20px;
  text-align: justify;
}

.aboutHome .item_flexSB:hover {
  background-color: #004AAD;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 5px 20px -2px rgb(0 0 0 / 6%);
  padding: 15px;
}

.aboutHome .item_flexSB:hover p {
  color: #fff;
}

/* Media Queries for Responsiveness */

/* Tablets and larger phones */
@media screen and (max-width: 992px) {
  .aboutHome .right {
    padding: 50px 30px;
  }

  .specialties-cards {
    flex-direction: column;
  }

  .specialty-card {
    flex: 1 1 100%; /* Full width on smaller screens */
    margin: 5px 0; /* Adjust margin for single column layout */
  }
}

/* Smaller tablets and large phones */
@media screen and (max-width: 768px) {
  .aboutHome .right {
    padding: 30px 20px;
  }

  .about-overview-box,
  .specialties-box {
    padding: 20px; /* Reduce padding */
    margin-top: 15px; /* Adjust margin */
    margin-bottom: 15px; /* Adjust margin */
  }

  .specialties-box h2 {
    font-size: 1.25rem; /* Adjust font size */
  }

  .specialty-card {
    padding: 15px; /* Reduce padding */
  }

  .aboutHome .item_flexSB p {
    font-size: 18px; /* Reduce font size */
  }
}

/* Small phones */
@media screen and (max-width: 576px) {
  .about-container {
    padding: 15px; /* Reduce padding */
  }

  .about-overview-box,
  .specialties-box {
    padding: 15px; /* Reduce padding */
  }

  .specialties-box h2 {
    font-size: 1rem; /* Adjust font size */
    margin-bottom: 15px; /* Adjust margin */
  }

  .specialty-card {
    padding: 10px; /* Reduce padding */
  }

  .aboutHome .right {
    padding: 20px 10px; /* Adjust padding */
  }

  .aboutHome .item_flexSB h2 {
    font-size: 20px; /* Adjust font size */
  }

  .aboutHome .item_flexSB p {
    font-size: 16px; /* Adjust font size */
  }

  .aboutHome .left img {
    height: auto; /* Let the image height adjust automatically on small screens */
  }

  .specialty-card {
    padding: 15px; /* Ensure text does not get cut off */
    margin: 10px 5px; /* Add margin to prevent cutting off */
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(100% - 20px); /* Full width on small screens */
  }

  .about-overview-box, .specialties-box {
    margin-left: 5px;
    margin-right: 5px;
  }
}


/*----------awrapper------------*/
.awrapper {

  border-radius: 15px;
  padding-top: 15px;
  width: 75vw;
  background-color: #f7f5f5;
  margin: 50px auto;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.8), 
              0 0 8px rgba(255, 255, 255, 0.6), 
              0 0 10px rgba(255, 255, 255, 0.4), 
              0 0 15px rgba(255, 255, 255, 0.2);
}

.aw_img {
  font-size: 62px;
  color: #004AAD;
  margin-right: 20px;
}

.awrapper h1 {
  font-size: 40px;
}

.awrapper h3 {
  font-size: 20px;
  font-weight: 500;
  margin-left: auto;
}

/* Media Queries for Responsiveness */

/* Tablets and large phones */
@media screen and (max-width: 992px) {
  .awrapper {
    width: 85vw;
    padding-left: 15px;
    padding-right: 15px;
    margin: 40px auto;
  }
  
  .aw_img {
    font-size: 50px;
    margin-right: 20px;
  }

  .awrapper h1 {
    font-size: 32px;
  }

  .awrapper h3 {
    font-size: 18px;
  }
}

/* Phones */
@media screen and (max-width: 768px) {
  .awrapper {
    width: 90vw;
    padding-left: 10px;
    padding-right: 10px;
    margin: 30px auto;
    height: auto; /* Adjust height for better fit on small screens */
  }

  .aw_img {
    font-size: 40px;
    margin-right: 15px;
  }

  .awrapper h1 {
    font-size: 28px;
  }

  .awrapper h3 {
    font-size: 16px;
  }

  .aboutHome .container {
    flex-direction: column-reverse;
  }

  .aboutHome .row {
    width: 100%;
  }
}

/* Small phones */
@media screen and (max-width: 576px) {
  .awrapper {
    width: 95vw;
    padding-left: 5px;
    padding-right: 5px;
    margin: 20px auto;
    height: auto; /* Adjust height for better fit on small screens */
  }

  .aw_img {
    font-size: 32px;
    margin-right: 10px;
  }

  .awrapper h1 {
    font-size: 24px;
  }

  .awrapper h3 {
    font-size: 14px;
  }
}

/* Specific styles for additional elements */
#learn_anything {
  /* color: #004AAD; */
  font-weight: 500;
  font-size: 2.3rem;
  margin-bottom: 30px;
  margin-top: -20px;
}

#title_text {
  color: #004AAD;
}

.online-courses {
  text-align: center;
  padding: 20px; /* Add some padding for smaller screens */
}

.courses-heading {
  margin-bottom: 40px;
  margin-top: 50px;
  font-size: 44px;
}

.courses-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.course-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 520px;
  width: 350px;
  margin: 10px;
  background-color: #f4f4f4;
  border: 2px solid #004AAD;
  border-radius: 5%;
  padding: 10px;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.course-card:hover {
  transform: scale(1.05);
}

.content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-container {
  margin-bottom: 10px;
  height: 80px;
  width: 80px;
}

h3, p {
  text-align: center;
  margin: 5px 0;
}

button.know-more-button {
  background-color: #004AAD;
  color: #fff;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

/* Media Queries for Maximum Responsiveness */

/* Tablets and up */
@media (max-width: 992px) {
  .courses-flex {
    justify-content: center; /* Center align the cards on smaller screens */
  }

  .course-card {
    width: 45%; /* Adjust card width for tablets */
    margin: 15px 5px; /* Adjust margins for better spacing */
  }
}

/* Small Devices (phones) */
@media (max-width: 576px) {
  .courses-heading {
    font-size: 32px; /* Adjust heading size for smaller screens */
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .course-card {
    width: 100%; /* Full width for smaller screens */
    margin: 10px 0; /* Adjust margin for better spacing */
    height: auto; /* Auto height for better adaptability */
  }

  .icon-container {
    height: 60px; /* Adjust icon size for smaller screens */
    width: 60px;
  }

  h3, p {
    font-size: 16px; /* Adjust text size for smaller screens */
  }

  button.know-more-button {
    padding: 8px 12px; /* Adjust button padding */
    font-size: 14px; /* Adjust button text size */
  }
}

/* Existing styles */
.testimonial-section {
  margin-top: 20px;
  padding: 20px;
}

.testimonial_h1 {
  font-size: 32px;
  color: #004AAD;
  margin-top: 20px;
  text-align: center; /* Center align the heading */
}

.T-carousel-container {
  max-width: 100%;
  margin: 0 auto;
}

.T-carousel-item-padding-40-px {
  padding: 40px;
}

.T-slide {
  position: relative;
}

.T-slide img {
  width: 100%;
  height: auto; /* Adjusted to maintain aspect ratio */
  max-height: 750px; /* Max height for larger screens */
  object-fit: contain; /* Ensure the entire image is displayed */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.T-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  text-align: center;
}

/* Button Styles */
.primary {
  background-color: #004AAD;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.primary:hover {
  background-color: #003b8b;
}

.mx-5 {
  margin-left: 20px;
  margin-right: 20px;
}

.my-5 {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .testimonial_h1 {
    font-size: 28px;
  }

  .T-slide img {
    max-height: 600px; /* Adjusted max height */
  }

  .primary {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 992px) {
  .testimonial_h1 {
    font-size: 24px;
  }

  .T-slide img {
    max-height: 500px; /* Adjusted max height */
  }

  .primary {
    font-size: 12px;
    padding: 6px 12px;
  }

  .mx-5 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .my-5 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .testimonial_h1 {
    font-size: 20px;
  }

  .T-slide img {
    max-height: 400px; /* Adjusted max height */
  }

  .primary {
    font-size: 10px;
    padding: 5px 10px;
  }

  .mx-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .my-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (max-width: 576px) {
  .testimonial_h1 {
    font-size: 18px;
  }

  .T-slide img {
    max-height: 300px; /* Adjusted max height */
  }

  .primary {
    font-size: 8px;
    padding: 4px 8px;
  }
}

.gallery-section {
  margin-top: 20px;
  padding: 20px;
}
.gallery_h1{
  font-size: 32px;
  color: #004AAD;
  margin-top: 20px;
}

.G-carousel-container {
  max-width: 100%;
  margin: 0 auto;
}

.G-carousel-item-padding-40-px {
  padding: 40px;
}

.G-slide{
  position: relative;
}

.G-slide img {
  width: 100%; /* Set the width to 100% for responsiveness */
  height: 400px; /* Set the desired height for all images */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.G-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  text-align: center;
}



/* Media Queries */

/* Tablets and up */
@media (min-width: 768px) {
  .gallery_h1 {
    font-size: 36px; /* Adjust heading size for tablets */
  }

  .G-carousel-item-padding-40-px {
    padding: 30px; /* Adjust padding for tablets */
  }

  .G-slide img {
    height: 500px; /* Adjust max height for tablets */
  }

  .G-caption {
    padding: 15px; /* Adjust caption padding for tablets */
  }
}

/* Desktops and up */
@media (min-width: 992px) {
  .gallery_h1 {
    font-size: 40px; /* Adjust heading size for desktops */
  }

  .G-carousel-item-padding-40-px {
    padding: 20px; /* Adjust padding for desktops */
  }

  .G-slide img {
    height: 600px; /* Adjust max height for desktops */
  }

  .G-caption {
    padding: 20px; /* Adjust caption padding for desktops */
  }
}

/* Large Desktops and up */
@media (min-width: 1200px) {
  .gallery_h1 {
    font-size: 44px; /* Adjust heading size for large desktops */
  }

  .G-carousel-item-padding-40-px {
    padding: 10px; /* Adjust padding for large desktops */
  }

  .G-slide img {
    height: 700px; /* Adjust max height for large desktops */
  }

  .G-caption {
    padding: 25px; /* Adjust caption padding for large desktops */
  }
}
/* events.css */

/* Styles for the entire layout */
.events-container {
  display: flex;
  flex-direction: column;
}

.poster-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px; /* Ensure padding on smaller screens */
}

.poster h2 {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 44px;
  color: #004AAD;
}

.poster-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.poster-card .poster-details {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0; /* Initially hide details */
  transition: opacity 0.3s ease, font-size 0.3s ease, font-weight 0.3s ease; /* Smooth transition */
}

.poster-card:hover .poster-details {
  opacity: 1;
}

.poster-card:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
}

.poster-card img {
  width: 100%;
  height: auto;
  transition: filter 0.3s ease; /* Smooth transition for blur effect */
}

.poster-details p {
  margin: 5px 0;
  color: white; /* Adjust text color to contrast with image */
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

/* Slider */
.events_h1 {
  font-size: 32px;
  color: #004AAD;
  margin-top: 20px;
  text-align: center;
}

.events-section {
  margin-top: 20px;
  padding: 20px;
}

.E-carousel-container {
  max-width: 100%;
  margin: 0 auto;
}

.E-carousel-item-padding-40-px {
  padding: 40px;
}

.E-slide {
  position: relative;
}

.E-slide img {
  width: 100%; /* Set the width to 100% for responsiveness */
  height: auto; /* Maintain aspect ratio */
  max-height: 600px; /* Set the desired max height for all images */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.E-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  text-align: center;
}

/* Adjust styles for smaller screens */
@media screen and (max-width: 768px) {
  .poster-grid {
    margin-left: 10px;
    margin-right: 10px;
  }

  .poster-card .poster-details {
    font-size: 16px; /* Slightly smaller font size for smaller screens */
  }

  .E-carousel-item-padding-40-px {
    padding: 20px; /* Reduce padding for smaller screens */
  }

  .E-slide img {
    height: 400px; /* Adjust max height for smaller screens */
  }

  .E-caption {
    padding: 8px; /* Adjust caption padding for smaller screens */
  }
}

/* Adjust styles for tablets and up */
@media (min-width: 768px) {
  .poster-grid {
    grid-gap: 20px;
    gap: 20px;
    padding: 0 40px; /* Adjust padding for tablets */
  }

  .poster h2 {
    font-size: 36px; /* Adjust heading size for tablets */
  }

  .E-carousel-item-padding-40-px {
    padding: 30px; /* Adjust padding for tablets */
  }

  .E-slide img {
    height: 500px; /* Adjust max height for tablets */
  }
}

/* Adjust styles for desktops and up */
@media (min-width: 992px) {
  .poster-grid {
    grid-gap: 30px;
    gap: 30px;
    padding: 0 60px; /* Adjust padding for desktops */
  }

  .poster h2 {
    font-size: 40px; /* Adjust heading size for desktops */
  }

  .E-carousel-item-padding-40-px {
    padding: 40px; /* Maintain padding for desktops */
  }

  .E-slide img {
    height: 600px; /* Adjust max height for desktops */
  }
}

/* Adjust styles for large desktops and up */
@media (min-width: 1200px) {
  .poster-grid {
    grid-gap: 40px;
    gap: 40px;
    padding: 0 80px; /* Adjust padding for large desktops */
  }

  .poster h2 {
    font-size: 44px; /* Adjust heading size for large desktops */
  }

  .E-carousel-item-padding-40-px {
    padding: 50px; /* Adjust padding for large desktops */
  }

  .E-slide img {
    height: 700px; /* Adjust max height for large desktops */
  }
}


.Learn_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-around;
  /* background-image: url("./7.jpg"); */
  background-size: cover;
  background-position: center;
  padding: 20px;
}
.Consult_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-around;
  /* background-image: url("./1.jpg"); */
  background-size: cover;
  background-position: center;
  padding: 20px; /* Adjust as needed */
}
.head_service {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 40px;
  color: #004AAD;
}
#servicecard{
  max-height: 600px;
}
.service-title {
  color: #004AAD;
  font-size: 24px;
  margin-bottom: 10px;
}
#con_title{
  font-size: 3rem;
}
#learn_title{
  font-size: 3rem;
}
.s_items {
  padding: 20px;
  border: 1px solid #004AAD;
  border-radius: 8px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

h3 {
  text-align: center;
  margin-bottom: 30px;
}

h4,
p {
  color: #004AAD;
  margin-bottom: 10px;
}

.outline-btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #004AAD;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: auto;
}

.outline-btn:hover {
  background-color: #fcfcfc;
  color: #004AAD;
}

.Ser_description ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

.Ser_description li {
  color: #004AAD;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.Ser_description .bullet-icon {
  margin-right: 8px;
  color: #004AAD;
}
/* Media Queries */

/* Small Devices (Tablets) */
@media (min-width: 768px) {
  .Learn_container, .Consult_container {
    margin-left: 30px; /* Adjust margin for tablets */
    margin-right: 30px; /* Adjust margin for tablets */
  }

  .service-title {
    font-size: 28px;
  }


}

/* Medium Devices (Desktops) */
@media (min-width: 992px) {
  .Learn_container, .Consult_container {
    
    margin-left: 40px; /* Adjust margin for desktops */
    margin-right: 40px; /* Adjust margin for desktops */
  }

  .service-title {
    font-size: 32px;
  }


}

/* Large Devices (Large Desktops) */
@media (min-width: 1200px) {
  .Learn_container, .Consult_container {
    margin-left: 50px; /* Adjust margin for large desktops */
    margin-right: 50px; /* Adjust margin for large desktops */
  }

  .service-title {
    font-size: 36px;
  }


}
.blog_container {
  margin-bottom: 70px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 40px;
  gap: 40px;
  justify-content: center;
}

.blog_item {
  width: 100%;
  max-width: 600px;
  margin: 40px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog_item .blog_img img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.blog_item .blog_text {
  padding: 40px 30px;
}

.blog_item h1 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin: 20px 0;
  transition: 0.5s;
  text-align: left;
}

.blog_item h1:hover {
  color: #027dff;
  cursor: pointer;
}

.blog_item span {
  text-transform: uppercase;
  font-weight: 500;
  color: #027dff;
}

.blog_item span label {
  margin-left: 10px;
  color: rgb(0, 0, 0);
  font-size: 14px;
}

.blog_item span:nth-last-child(1) label {
  color: #027dff;
}

.blog_item p {
  color: rgb(0, 0, 0);
  text-align: justify;
}

.blog_button_container {
  text-align: center;
  margin-top: auto;
  padding: 20px 0;
}

.blog_button_container button {
  background-color: #027dff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.blog_button_container button:hover {
  background-color: #025bbf;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .blog_item {
    margin: 20px;
  }
  
  .blog_item .blog_text {
    padding: 30px 20px;
  }
}

@media (max-width: 768px) {
  .blog_item {
    margin: 20px 10px;
  }

  .blog_item .blog_text {
    padding: 20px 10px;
  }

  .blog_item h1 {
    font-size: 20px;
  }

  .blog_item p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .blog_item {
    margin: 10px 5px;
  }

  .blog_item .blog_text {
    padding: 15px 10px;
  }

  .blog_item h1 {
    font-size: 18px;
  }

  .blog_item p {
    font-size: 12px;
  }
}

/* contact.css */

.container_con {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 0rem;
  gap: 0rem;
  border: 2px solid #ddd;
  border-radius: 15px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#id_for_img {
  min-height: 400px; /* Set a minimum height for the image container */
  background-image: url(/static/media/15.a9f71d2e.svg); /* Path to your image */
  background-size: cover;
  background-position: center;
}

#right_col {
  background-color: #fff;
  min-height: 400px; /* Set a minimum height for the right column */
}

@media (max-width: 768px) {
  .container_con {
    flex-direction: column;
  }

  .address-email-phone {
    flex-direction: column;
  }

  .items,
  form {
    width: 100%;
  }

  #id_for_img,
  #right_col {
    min-height: 300px; /* Adjust the minimum height for smaller screens */
  }
}

.left {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.left img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
}

.right {
  border-radius: 15px;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
}

.address-email-phone {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 20px;
}

.address-box,
.email-phone-box {
  flex: 1 1;
  border: 3px solid #004aad;
  border-radius: 8px;
  padding: 20px;
}

.email-phone-box > div {
  margin-bottom: 20px;
}

h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #004aad;
}

form {
  margin-top: 20px;
}

button {
  background-color: #004aad;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #003788;
}

#c-social {
  margin-top: 20px;
}

#c-social a i {
  font-size: 1.7rem;
  margin-right: 10px;
  color: #004aad;
}

.contacts h3 {
  font-size: 2.5rem;
  margin-top: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #004aad;
}

#con1 {
  color: #004aad;
}

.contacts textarea,
.contacts input {
  width: 100%;
  padding: 15px;
  margin-bottom: 5px;
  outline: none;
  border: 1px solid grey;
  background-color: #fff;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 15px;
}

#countryCode {
  height: 55px;
  margin-right: 4px;
  border-color: #ddd; /* Changed from black to match the container border */
}

#country {
  width: 100%;
  height: 55px;
  border-color: #ddd; /* Changed from black to match the container border */
}

.right p,
h4 {
  color: #004aad;
}

.flexSB {
  display: flex;
  justify-content: space-between;
}

.popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #004aad;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup p {
  margin-bottom: 10px;
}

.popup button {
  background-color: #004aad;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup button:hover {
  background-color: #003788;
}

/* Remove list styles and padding from ul elements in the footer */
#exp ul,
#quick ul {
  list-style: none;
  padding: 0;
}

/* Add margin-bottom to li elements in the footer */
#exp li,
#quick li {
  margin-bottom: 10px;
}

.s_media_icon{
  font-size: 2rem;
  padding-right: 1rem;
 
}

.s_media_icon:hover{
  color: #0a0a0a;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: scale(1.1);
}

/* Remove text decoration and display inline-block for anchor elements in the footer */
#exp a,
#quick a {
  text-decoration: none;
  display: inline-block;
}

/* Styling for the footer logo */
#vtm_flogo {
  margin: 20px auto 20px auto;
  padding-bottom: 20px;
  max-width: 100%;
  height: auto;
}

/* Center content and add padding to the footer */
footer {
  justify-content: center;
  padding: 20px 0;
}

/* Styles for the footer container */
footer .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 40px;
  justify-content: center;
  text-align: center;
}

/* Styles for the footer logo icon */
footer .logo .icon {
  /* background-color: hsl(214, 100%, 34%); */
  color: rgb(9, 71, 207);
  margin-right: 15px;
  
}

/* Styles for footer headings */
footer h3 {
  margin-bottom: 20px;
  font-weight: 500;
}

/* Margin-bottom for list items in the footer */
footer ul li {
  margin-bottom: 10px;
}

/* Styles for the last section of the footer */
footer .last ul li {
  margin-bottom: 20px;
}

/* Styles for icons in the last section of the footer */
footer .last i {
  font-size: 20px;
  color: #004AAD;
}

/* Styles for legal section */
.legal {
  text-align: center;
  padding: 20px 0;
  color: grey;
  background-color: #e9e9e9;
}

/* Styles for legal section paragraphs */
.legal p {
  font-size: 16px;
  font-weight: bold;
}

/* Color for icons in legal section */
.legal i {
  color: #004AAD;
}

/* Hblog.css */
.h-blog {
    margin-top: 20px; /* Adjust margin as needed */
  }
  
  .Hb-container {
    max-width: 1200px;
    
    margin: 0 auto;
  }
  
  #blog_title h1 {
    font-size: 42px;
    margin-bottom: 10px;
  }
  
  #blog_title h2 {
    font-size: 34px;
    margin-bottom: 30px;
  }
  
  .carousel-container {
    max-height: 700px; /* Adjust the max height of the carousel */
  }
  
  .H_b-slide {
    background-color: #fff; /* Adjust background color as needed */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adjust shadow as needed */
    padding: 20px;
    margin-right: 20px; /* Adjust margin between slides as needed */
    height: 700px; /* Set fixed height for carousel slides */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 60px;
  }
  
  .H_b-cover img {
    max-width: 100%;
    height: 200px; /* Set fixed height for images */
    border-radius: 8px;
  }
  
  .H_b-text {
    padding: 20px 0;
  }
  .H_b-text label{
    margin-left: 10px;
  }
  
  .H_b-text h1 {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .H_b-text p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px; /* Add margin bottom for paragraph */
    text-align: justify;
    margin-top: 20px;
  }
  
  .H_b-read {
    text-align: center;
  }
  
  .H_b-read button {
    background-color: #007bff; /* Button background color */
    color: #fff; /* Button text color */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .H_b-read button:hover {
    background-color: #0056b3; /* Button background color on hover */
  }
  
.H_c-courses-section {
    text-align: center; 
    margin-top: -30rem;
  }

  .H_c-h1{
    font-size: 40px;
    margin-bottom: 10px;
  }

  
  .H_c-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Set the width to 100% for responsiveness */
    height: 550px;
    
  }
  
  .H_c-slide .H_c-caption {
    border: 2px solid #004AAD;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    height: 500px; 
    width : 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s inherit;
    /* margin-bottom: 30px; */
  }

  .H_c-view{
    margin-top: 40px;
    /* margin-bottom: 40px; */
  }

  .H_c-slide:hover .H_c-caption {
    transform: scale(1.005);
  }
  
  .H_c-slide .H_c-caption h3 {
    font-size: 28px; /* Adjust the font size as needed */
    margin-bottom: 10px;
    color: #004AAD;
  }
  
  .H_c-slide .H_c-caption p {
    font-size: 22px; /* Adjust the font size as needed */
    margin-bottom: 10px;
  color: #004AAD;
  }
  
  .H_c-slide .H_c-caption strong {
    font-weight: bold;
  }

  .H_c-icon{
    color: #004AAD;
    height: 80px;
    width: 80px;
  }
  

#image_col {
  margin-top: 70px;
  border-radius: 2rem;
  background-image: url(/static/media/bgg.e6bf29c4.png);
  background-size: cover; /* Ensure the background image covers the entire div */
  background-position: right; /* Align the background image to the right */
  background-repeat: no-repeat;
  height: 75vh;
  width: 50%;
  /* background-attachment: fixed; */
}
@media (max-width: 760px) {
  #image_col {
    width: 90%;
    margin-left: 1.5rem;
    background-position: center; /* Align the background image to the right */


  }
}


#main_page{
  background-color: #ffffff;
}

#hero_heading h1 {
  color: #004aad;
  margin-top: 70px;
  text-align: left;
  margin-left: 40px;
  font-size: 41px;
}

#hero_heading h2 {
  color: #004aad;
  text-align: left;
  margin-left: 40px;
  font-size: 24px;
  margin-top: 20px;
}

.hero-text p {
  margin-bottom: 30px; /* Adjusted margin for better spacing */
  text-align: justify;
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 15px;
  font-size: 16px;
  padding: 10px;
}

.hero_button {
  /* margin-top: 12px;  */
  cursor: pointer;
  margin-left: 50px;
  height: 50px;
  /* margin-bottom: auto; */
}

.get-started-btn,
.view-course-btn {
  background-color: #004aad;
  color: #ffffff;
  border: 2px solid #004aad;
  transition: background-color 0.3s, color 0.3s;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  text-align: left;
  margin-right: 50px;
}

.get-started-btn:hover,
.view-course-btn:hover {
  background-color: #ffffff;
  color: #004aad;
}

/* Media Queries for Maximum Responsiveness */

/* Small Devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  #hero_heading h1 {
    font-size: 32px; /* Adjust font size for smaller screens */
    margin-left: 20px; /* Adjust margin for smaller screens */
  }

  #hero_heading h2 {
    font-size: 20px; /* Adjust font size for smaller screens */
    margin-left: 20px; /* Adjust margin for smaller screens */
    margin-top: 10px; /* Adjust margin for smaller screens */
  }

  .hero-text p {
    margin-left: 20px; /* Adjust margin for smaller screens */
    margin-right: 10px; /* Adjust margin for smaller screens */
  }

  
}

/* Medium Devices (tablets, 768px and up) */
@media (min-width: 768px) {
  #hero_heading h1 {
    font-size: 36px; /* Adjust font size for medium screens */
    margin-left: 40px; /* Adjust margin for medium screens */
  }

  #hero_heading h2 {
    font-size: 22px; /* Adjust font size for medium screens */
    margin-left: 40px; /* Adjust margin for medium screens */
    margin-top: 20px; /* Adjust margin for medium screens */
  }

  .hero-text p {
    margin-left: 40px; /* Adjust margin for medium screens */
    margin-right: 20px; /* Adjust margin for medium screens */
  }

  
}

/* Large Devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #hero_heading h1 {
    font-size: 41px; /* Reset font size for large screens */
    margin-left: 40px; /* Reset margin for large screens */
  }

  #hero_heading h2 {
    font-size: 24px; /* Reset font size for large screens */
    margin-left: 40px; /* Reset margin for large screens */
    margin-top: 20px; /* Reset margin for large screens */
  }

  .hero-text p {
    margin-left: 40px; /* Reset margin for large screens */
    margin-right: 15px; /* Reset margin for large screens */
  }

  
}
.H_s_l-services-section{
    text-align: center;
    
  }

  .H_s_l-h1{
    font-size: 40px;
    margin-top: 10px;

  }
  .h_s_l-h2{
    font-size: 35px;
    margin-top: 5px;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }

  
  .H_s_l-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Set the width to 100% for responsiveness */
    height: 520px;
    margin-top: 0px;
  }
  
  .H_s_l-slide .H_s_l-caption {
    border: 2px solid #004AAD;
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    text-align: center;
    height: 420px; 
    width : 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s inherit;
  }

  .H_s_l-view{
    margin-top: 5px;
    margin-bottom: 10px;
    justify-content: center;
  }

  .H_s_l-slide:hover .H_s_l-caption {
    transform: scale(1.005);
  }
  
  .H_s_l-slide .H_s_l-caption h3 {
    font-size: 28px; 
    color: #004AAD;
  }
  
  .H_s_l-slide .H_s_l-caption p {
    font-size: 22px; 
  color: #004AAD;
  }
  
  .H_s_l-slide .H_s_l-caption strong {
    font-weight: bold;
  }

  .H_s_l-icon{
    color: #004AAD;
    font-size: 60px;
  }
  

 /* consulting */

  .H_s_c-services-section{
    text-align: center;
    
  }

  .h_s_c-h2{
    font-size: 35px;
    margin-top: 5px;
    justify-content: center;
    align-items: center;
  }

  
  .H_s_c-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Set the width to 100% for responsiveness */
    height: 520px;
    margin-top: 0px;
  }
  
  .H_s_c-slide .H_s_c-caption {
    border: 2px solid #004AAD;
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    text-align: center;
    height: 400px; 
    width : 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s inherit;
  }

  .H_s_c-view{
    margin-top: 10px;
    justify-content: center;
  }

  .H_s_c-slide:hover .H_s_c-caption {
    transform: scale(1.005);
  }
  
  .H_s_c-slide .H_s_c-caption h3 {
    font-size: 28px; 
    color: #004AAD;
  }
  
  .H_s_c-slide .H_s_c-caption p {
    font-size: 22px; /* Adjust the font size as needed */
    /* margin-bottom: 8px; */
  color: #004AAD;
  }
  
  
  .H_s_c-slide .H_s_c-caption strong {
    font-weight: bold;
  }

  .H_s_c-icon{
    color: #004AAD;
    font-size: 60px;
  }
  
.H_t-testimonial-section {
  text-align: center;
}

.H_t-h1 {
  font-size: 40px;
  margin-bottom: 10px;
  margin-top: 40px;
}

.H_t-h2 {
  font-size: 30px;
  margin-bottom: 5px;
}

.H_t-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 650px;
}

.H_t-slide .H_t-caption {
  border: 2px solid #004AAD;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  height: 600px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s inherit;
  margin-bottom: 30px;
}


.H_t-slide:hover .H_t-caption {
  transform: scale(1.005);
}

.H_t-slide .H_t-caption h3 {
  font-size: 28px;
  margin-bottom: 10px;
  margin-top: 5px;
  color: #004AAD;
}

.H_t-slide .H_t-caption{
  font-size: 22px;
  margin-bottom: 10px;
  color: #004AAD;
}

.H_t-icon {
  color: #004AAD;
  border-radius: 50%;
  height: 150px;
  width: 150px;
}



.H_client-section {
  padding: 20px;
  background-color: #fff; /* White ribbon background */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for a 3D effect */
}

.H_clnt-h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

.slider-container {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.slider {
  display: flex;
  width: calc(200%); /* To accommodate the seamless loop */
  animation: slide 20s linear infinite;
}

@keyframes slide {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

.slide {
  min-width: 10%;
  box-sizing: border-box;
  text-align: center;
}

.H_clnt-icon {
  width: 100%;
  max-width: 150px;
  height: auto;
  margin: 0 auto;
  display: block;
}

.H_clnt-caption {
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .H_clnt-h1 {
    font-size: 1.75rem;
  }

  .H_clnt-icon {
    max-width: 120px;
  }
}

@media (max-width: 464px) {
  .H_clnt-h1 {
    font-size: 1.5rem;
  }

  .H_clnt-icon {
    max-width: 100px;
  }
}

