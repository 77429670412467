/* Hblog.css */
.h-blog {
    margin-top: 20px; /* Adjust margin as needed */
  }
  
  .Hb-container {
    max-width: 1200px;
    
    margin: 0 auto;
  }
  
  #blog_title h1 {
    font-size: 42px;
    margin-bottom: 10px;
  }
  
  #blog_title h2 {
    font-size: 34px;
    margin-bottom: 30px;
  }
  
  .carousel-container {
    max-height: 700px; /* Adjust the max height of the carousel */
  }
  
  .H_b-slide {
    background-color: #fff; /* Adjust background color as needed */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adjust shadow as needed */
    padding: 20px;
    margin-right: 20px; /* Adjust margin between slides as needed */
    height: 700px; /* Set fixed height for carousel slides */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 60px;
  }
  
  .H_b-cover img {
    max-width: 100%;
    height: 200px; /* Set fixed height for images */
    border-radius: 8px;
  }
  
  .H_b-text {
    padding: 20px 0;
  }
  .H_b-text label{
    margin-left: 10px;
  }
  
  .H_b-text h1 {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .H_b-text p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px; /* Add margin bottom for paragraph */
    text-align: justify;
    margin-top: 20px;
  }
  
  .H_b-read {
    text-align: center;
  }
  
  .H_b-read button {
    background-color: #007bff; /* Button background color */
    color: #fff; /* Button text color */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .H_b-read button:hover {
    background-color: #0056b3; /* Button background color on hover */
  }
  