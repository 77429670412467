/* Remove list styles and padding from ul elements in the footer */
#exp ul,
#quick ul {
  list-style: none;
  padding: 0;
}

/* Add margin-bottom to li elements in the footer */
#exp li,
#quick li {
  margin-bottom: 10px;
}

.s_media_icon{
  font-size: 2rem;
  padding-right: 1rem;
 
}

.s_media_icon:hover{
  color: #0a0a0a;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: scale(1.1);
}

/* Remove text decoration and display inline-block for anchor elements in the footer */
#exp a,
#quick a {
  text-decoration: none;
  display: inline-block;
}

/* Styling for the footer logo */
#vtm_flogo {
  margin: 20px auto 20px auto;
  padding-bottom: 20px;
  max-width: 100%;
  height: auto;
}

/* Center content and add padding to the footer */
footer {
  justify-content: center;
  padding: 20px 0;
}

/* Styles for the footer container */
footer .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 40px;
  justify-content: center;
  text-align: center;
}

/* Styles for the footer logo icon */
footer .logo .icon {
  /* background-color: hsl(214, 100%, 34%); */
  color: rgb(9, 71, 207);
  margin-right: 15px;
  
}

/* Styles for footer headings */
footer h3 {
  margin-bottom: 20px;
  font-weight: 500;
}

/* Margin-bottom for list items in the footer */
footer ul li {
  margin-bottom: 10px;
}

/* Styles for the last section of the footer */
footer .last ul li {
  margin-bottom: 20px;
}

/* Styles for icons in the last section of the footer */
footer .last i {
  font-size: 20px;
  color: #004AAD;
}

/* Styles for legal section */
.legal {
  text-align: center;
  padding: 20px 0;
  color: grey;
  background-color: #e9e9e9;
}

/* Styles for legal section paragraphs */
.legal p {
  font-size: 16px;
  font-weight: bold;
}

/* Color for icons in legal section */
.legal i {
  color: #004AAD;
}
