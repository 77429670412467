.H_client-section {
  padding: 20px;
  background-color: #fff; /* White ribbon background */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for a 3D effect */
}

.H_clnt-h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

.slider-container {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.slider {
  display: flex;
  width: calc(200%); /* To accommodate the seamless loop */
  animation: slide 20s linear infinite;
}

@keyframes slide {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

.slide {
  min-width: 10%;
  box-sizing: border-box;
  text-align: center;
}

.H_clnt-icon {
  width: 100%;
  max-width: 150px;
  height: auto;
  margin: 0 auto;
  display: block;
}

.H_clnt-caption {
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .H_clnt-h1 {
    font-size: 1.75rem;
  }

  .H_clnt-icon {
    max-width: 120px;
  }
}

@media (max-width: 464px) {
  .H_clnt-h1 {
    font-size: 1.5rem;
  }

  .H_clnt-icon {
    max-width: 100px;
  }
}
